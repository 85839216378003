import React, { Component } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';

import TextBox from '../input/TextBox';
import DynamicInput from '../input/DynamicInput';

const styles = createStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  form: {
    margin: theme.spacing(1),
  },
  formItem: {
    margin: theme.spacing(2),
  }
}));



class ConfigStepper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      files: [],
    };
  }

  render() {
    const { classes, steps } = this.props;
    const { activeStep } = this.state;
    //const steps = getSteps();

    const handleNext = () => {
      this.setState({ activeStep: Math.min(activeStep + 1, steps.length) });
    };
    const handleBack = () => {
      this.setState({ activeStep: Math.max(activeStep - 1, 0) });
    };
    const handleReset = () => {
      this.setState({ activeStep: 0 });
    };
    const handleChange = (files) => {
      this.setState({ files: files  });
    }

    return (
      <Grid container justify="center" className={ classes.root }>
        <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
          <Stepper activeStep={activeStep} orientation="vertical">
            { steps?.map((step, key) => (
              <Step key={ key }>
                <StepLabel >
                  <Typography variant="h6">
                    { step.label }
                  </Typography>
                </StepLabel>
                <StepContent classes={{ root: classes.stepContent }}>

                  <Typography>
                    { step.description }
                  </Typography>

                  <form className={ classes.form } noValidate autoComplete="off">
                    <Grid container>
                      { step.inputs?.map((input, key) => (
                        <DynamicInput input={ input } classes={ classes }/>
                      ))}
                    </Grid>
                  </form>

                  <Box classes={{ root: classes.actionsContainer }}>
                    { activeStep !== 0 &&
                      <Button
                        disabled={ activeStep === 0}
                        onClick={ handleBack }
                        className={ classes.button }
                      >
                        Back
                      </Button>
                    }
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      { activeStep === steps.length - 1 ? 'Finish' : 'Next' }
                    </Button>
                  </Box>

                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} className={classes.resetContainer}>
              <Typography>All steps completed - you&apos;re finished</Typography>
              <Button onClick={handleReset} className={classes.button}>
                Reset
              </Button>
            </Paper>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ConfigStepper);
