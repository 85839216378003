import React, { Component } from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';


const styles = createStyles((theme) => ({
  root: {
    spacing: 2,
    alignItems: 'stretch',
    justify: 'space-between',
    padding: 0,
  },
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  card: {
    height: "100%",
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    //backgroundColor: '#f5f8fa',
    //backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  selectedCard: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  radioControl: {
    padding: 0,
  },
}));

function Option(props) {
  const { title, subtitle, description, prices, xs, featured, classes } = props;

  return (
    <Card classes={{ root: classes.card }}>
      <CardHeader
        title={ title }
        subheader={ subtitle }
        titleTypographyProps={{ align: 'center' }}
        subheaderTypographyProps={{ align: 'center' }}
        action={ featured ? <StarIcon /> : null }
        className={ classes.cardHeader }
      />
      <CardContent>
        { prices?.map((tier) => (
          <div className={classes.cardPricing}>
            <Typography variant="h6" color="textSecondary">
              { tier.name }
            </Typography>
            <Typography component="h2" variant="h3" color="textPrimary">
              ${ tier.price }
            </Typography>
            <Typography variant="h6" color="textSecondary">
              { tier.priceBasis }
            </Typography>
          </div>
        ))}
        <ul>
          { description?.map((line) => (
            <Typography component="li" variant="subtitle1" align="center" key={line}>
              {line}
            </Typography>
          ))}
        </ul>
      </CardContent>
      <CardActions>
        <Button fullWidth color="primary">
          customize
        </Button>
      </CardActions>
    </Card>
  );
}

function RadioCard(props) {
  const { classes } = props;

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<Option className={clsx(classes.card, classes.selectedCard)} {...props} />}
      icon={<Option className={classes.card} {...props}/>}
      {...props}
    />
  );
}

class CardSelector extends Component {

  render() {
    const { options, classes, ...props } = this.props;

    const onChange = () => {

    }

    return (
      <FormControl component="fieldset">
        <Grid container spacing={2} alignItems="stretch">
          <RadioGroup row defaultValue="250" aria-label="Package Options" onChange={onChange}>

            { options?.map((option, key) => (
              <Grid item xs={12/options.length}>
                <FormControlLabel value={ key } control={
                    <RadioCard
                      { ...option }
                      classes={ classes }
                      xs={12/options.length}
                    />
                  }
                  classes={{ PrivateSwitchBase: classes.radioControl }}
                />
              </Grid>
            ))}
          </RadioGroup>
          { options?.map((option, key) => (
            <Grid item xs={12/options.length}>
              <Option classes={classes} {...option}/>
            </Grid>
          ))}
        </Grid>
      </FormControl>
    );
  }
}

export default withStyles(styles)(CardSelector);
