import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let Logo = (props) => (
  <SvgIcon {...props}>
    <g>
      <path d="M6.1,23.5C6.3,23.7,7,23.6,7,23.6S6.9,24,7.4,24c0.1,0,0.3-0.3,0.5-0.3c1.1-0.2,3.5,0.4,4.5,0c0.1,0,0.1-0.2,0.1-0.2
      	s2.9,0,3.9-0.1c0.3-0.1,1-0.3,1.2-0.5c0.2-0.2,0.4-0.7,0.4-1c0.3-1.7-0.3-5.3-0.8-7c-0.5-1.8-2.1-5.1-2.5-6.9
      	c-0.2-1-0.2-3.2-0.6-4.2c-0.3-0.6-1.4-2.2-1.4-2.2s-0.1-0.5-0.1-0.7c0-0.2-0.3-0.7-0.4-0.8C12,0,10-0.1,9.4,0.3
      	C8.9,0.5,8.7,1.2,8,1.6c-0.5,0.2-1.7,0-1.9,0.5C5.8,2.7,6.6,4.4,7.2,4.7c0.4,0.2,1.3,0.1,1.6-0.1C9,4.6,9.2,4.1,9.4,4.1
      	c0.4,0,1.1,0.7,1.2,1.1c0.2,0.5,0.2,1.6-0.1,2.1C10.3,7.8,9,8.6,8.8,9.1C8.5,9.8,9,14.2,8.4,17.1c-0.2,1-0.9,4.1-0.9,4.1
      	S6.2,22.2,6,22.7C5.9,22.9,5.9,23.3,6.1,23.5z M12.7,23c0-0.2,0.2-0.6,0.1-1.1c0.1,0.2,0.5,0.4,0.8,0.6c0.2,0.2,0.7,0.4,0.8,0.4
      	C14,23,13.1,23,12.7,23z M10.5,17.2c-0.2,0.1-0.4,0.2-0.6,0.3l1.2-2.4l2.2,2.4C12.9,17.3,11.7,16.8,10.5,17.2z M8.5,21.4
      	c0.2-0.2,0.4-0.2,0.6-0.2c0.2-0.1,0-0.5,0-1c0-0.1,0.2-1.1,0.3-1c0.4,0.3,0.2,1.4,1.4,2.6c0.1,0.1-0.1,0.3-0.2,0.3
      	c-0.5,0-1.5-0.1-2.1-0.2C8.5,21.9,8.4,21.5,8.5,21.4z"/>
    </g>
  </SvgIcon>
);
Logo = pure(Logo);
Logo.displayName = 'BigDog Group';
Logo.muiName = 'SvgIcon';

export default Logo;
