import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Slide from '@material-ui/core/Slide';


const styles = (theme) => createStyles({
  root: {
    color: "#FFFFFF",
    backgroundColor: theme.palette.secondary.light,
  },
  title: {
    color: theme.palette.primary.main,
  },
});


function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function AppBar(props) {
  const { classes } = props;

  return (
    <React.Fragment>
      <HideOnScroll {...props}>
        <MuiAppBar position="sticky">
          <Toolbar classes={{ root: classes.root }}>
            <Typography variant="h6" classes={{ root: classes.title }}>BigDog Group Store</Typography>
          </Toolbar>
        </MuiAppBar>
      </HideOnScroll>
    </React.Fragment>

  );
}

export default withStyles(styles)(AppBar);
