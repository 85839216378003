import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';

import Image from './components/Image';
import ProductCard from './components/ProductCard';
import ProductConfig from './components/ProductCard/ProductConfig';
import AppTray from './components/App/AppTray';
import AppDrawer from './components/App/AppDrawer';
import AppBar from './components/App/AppBar';
import BottomAppBar from './components/App/BottomAppBar';
import Logo from './logo';
import ModalButton from './components/ModalButton';

import theme from './components/core/Theme';

import './App.css';

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#61DAFB",
    borderColor: "#61DAFB",
    '&:hover': {
      backgroundColor: "rgba(256,256,256,.1)",
    },
  },
}))(Button);

const styles = (theme) => createStyles({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  root: {
    backgroundColor: "#282C34",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: "calc(10px + 2vmin)",
    color: "white",
    position: "relative",
    overflow: "hidden",
  },
  logoBkgd: {
    color: "#0000001f",
    position: "absolute",
    bottom: 0,
    right: -600,
    height: "90%",
    width: "auto",
    pointerEvents: "none",
    zIndex: 0,
  },
  productImageContainer: {
    alignItems: "right",
    overflow: "hidden",
  },
  productImage: {
    width: "125%",
    float: "right",
  },
  formContainer: {
    zIndex: 0,
  },
  formPaper: {
    padding: "24px",
    margin: "24px",
    zIndex: 1,
    color: "#FFFFFF",
    backgroundColor: "#00000020",
    borderColor: "#636a78",
  },
  stepper: {
    backgroundColor: "#00000000",
    borderColor: "#636a78",
  }
});

class App extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Router>
        <ThemeProvider theme={ theme }>
          <CssBaseline />
          <Grid container className="App">
            <AppBar/>
            {/*<AppDrawer />*/}
            <div classes={{ root:classes.toolbar }} />
            <Grid container classes={{ root: classes.root }}>
              <Grid item xs={12}>
                <Typography variant="h1">
                  Your Success Starts With First Impressions
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  <p>One box of your business cards is included with your big dog purchase. PLEASE USE CODE BIGDOG FOR $35 OFF YOUR ORDER - YOUR ONE FREE BOX OF 250 CARDS. Example. You would like 500 cards. Total for that is $55. You will pay $20 for your cards.</p>
                </Typography>
              </Grid>

              <Logo classes={{ root: classes.logoBkgd }} />
              <Grid item xs={12} sm={4} md={4} classes={{ root: classes.productImageContainer }}>
                <Image src="/assets/designs/businesscards/bigdog_businesscards_mockup.png" classes={{ root: classes.productImage }} width="125%"/>
              </Grid>
              <ProductConfig title="Business Cards"/>

              {/*<Grid item xs={12} sm={8} md={6} classes={{ root: classes.formContainer }}>

              </Grid>*/}
              <Grid item xs={false} md={2} />
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  <p>If you would like additional cards re-submit this form for each new person - but you are only able to use the BIGDOG code once. Also - if you would like cards for your other brands you would need to re-submit.</p>
                  <p>Our typical turn around time from submittal to preview is 3 business days. Then from approval to in your hands are typically 7 business days.</p>
                </Typography>
              </Grid>
            </Grid>
            <AppTray />
          </Grid>
          <BottomAppBar/>
        </ThemeProvider>
      </Router>
    );
  }
}

export default withStyles(styles)(App);
