import React, { Component } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {DropzoneArea} from 'material-ui-dropzone'

import TextBox from '../TextBox';
import CardSelector from '../CardSelector';


const styles = createStyles((theme) => ({
}));


class DynamicInput extends Component {

  render() {
    const { input, classes, onChange } = this.props;

    switch (input.type.toLowerCase()) {
      case "text":
        return (
          <Grid item xs={12} sm={6}>
            <TextBox
              className={classes.formItem}
              id={ input.id }
              label={ input.label }
              helperText={ input.helperText }
            />
          </Grid>
        );
      case "phone":
        return (
          <Grid item xs={12} sm={6}>
            <TextBox
              className={classes.formItem}
              id={ input.id }
              label={ input.label }
              helperText={ input.helperText }
            />
          </Grid>
        );
      case "email":
        return (
          <Grid item xs={12} sm={6}>
            <TextBox
              className={classes.formItem}
              id={ input.id }
              label={ input.label }
              helperText={ input.helperText }
            />
          </Grid>
        );
      case "dropzone":
        return (
          <Grid item xs={12}>
            <DropzoneArea
              acceptedFiles={ input.filetypes }
              onChange={ onChange }
            />
          </Grid>
        );
      case "priceoptions":
        return (
          <Grid item xs={12}>
            <CardSelector
              options={ input.options }
              onChange={ onChange }
            />
          </Grid>
        );
      default:
        return null;
    }
  }
}

export default withStyles(styles)(DynamicInput);
