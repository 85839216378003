const defaultPalette = {
  primary: {
    light: '#d8d8db',
    main: '#61DAFB',
    dark: '#002f86',
    contrastText: '#fff',
  },
  secondary: {
    light: '#636a78',
    main: '#282C34',
    dark: '#222222',
    contrastText: '#fff',
  },
}


export default defaultPalette;
