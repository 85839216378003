

const defaultProps = {
  MuiButtonBase: {

  }
}


export default defaultProps;
