import React, { Component } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import ConfigStepper from '../../ConfigStepper';

const styles = createStyles((theme) => ({
  root: {
    padding: "24px",
    margin: "24px",
    zIndex: 1,
    color: theme.palette.secondary.light,
    backgroundColor: "#00000020",
    borderColor: "#636a78",
  },
  stepper: {
    backgroundColor: "#00000000",
    borderColor: "#636a78",
  },
  appBar: {
    position: 'relative',
    color: theme.palette.secondary.light,
  },
  title: {
    flex: 1,
  },
  closeButton: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const steps = [
    {
      label: "Provide Contact Information",
      description: "Configure the design for your Business Card",
      inputs: [
        {
          id: "name",
          label: "Name",
          type: "text",
        },
        {
          id: "title",
          label: "Title",
          type: "text",
        },
        {
          id: "company",
          label: "Company Name",
          type: "text"
        },
        {
          id: "address",
          label: "Address",
          type: "text"
        },
        {
          id: "phone",
          label: "Phone Number",
          type: "phone"
        },
        {
          id: "email",
          label: "Email Address",
          type: "email"
        },
      ],
      actions: [
        {
          label: "Add Another Person",
        },
      ],
    },
    {
      label: "Upload Custom Logo",
      description: "Configure the design for your Business Card",
      inputs: [
        {
          id: "logo",
          label: "Company Logo",
          type: "DropZone",
          filetypes: ['image/*'],
        },
      ],
      actions: [
      ],
    },
    {
      label: "Select Package",
      description: "Choose the option that best fits your needs",
      inputs: [
        {
          id: "logo",
          label: "Company Logo",
          type: "PriceOptions",
          options: [
            {
              title: "250",
              subtitle: "Cards",
              price: "FREE",
              retailprice: "$35",
            },
            {
              title: "500",
              subtitle: "Cards",
              price: "$20",
              retailprice: "$55",
            },
            {
              title: "1000",
              subtitle: "Cards",
              price: "$35",
              retailprice: "$70",
            },
          ],
        },
      ],
      actions: [
      ],
    },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ProductConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  render() {
    const { classes, title } = this.props;
    const { open } = this.state;

    const multiStep = (steps.length > 1) ? true : false;

    const handleClose = () => {
      this.setState({ open: false });
    };
    const handleToggle = () => {
      this.setState({ open: !open });
    };

    return (
      <React.Fragment>
        <Button variant="outlined" color="primary" onClick={handleToggle}>
          Order Now!
        </Button>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar classes={{ root: classes.appBar }}>
            <Toolbar>
              <Typography variant="h6" classes={{ root: classes.title }}>BigDog Group Store</Typography>
              <Typography variant="h6" align="center" classes={{ root: classes.title }}>
                { title }
              </Typography>
              <Box classes={{ root: classes.closeButton }}>
                <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          <ConfigStepper classes={{ root: classes.stepper }} steps={ steps } orientation="vertical"/>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ProductConfig);
