import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

import defaultTypography from './Typography';
import defaultPalette from './Colors';
import defaultOverrides from './Overrides';
import defaultProps from './Props';


const defaultTheme = createMuiTheme({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    fontSize: 16,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
  palette: defaultPalette,
  typography: defaultTypography,
  overrides: defaultOverrides,
  props: defaultProps,
});

export const altTheme = createMuiTheme({
  props: {
    MuiButtonBase: {
      variant: "contained",
      size: 'large',
    },
  },
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    fontSize: 16,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
  palette: { defaultPalette }
}, defaultTypography);

export default responsiveFontSizes(defaultTheme);
